<template>
	<v-container>
		<div>
			<s-crud
				:filter="filter"
				:config="config"
				search-input
				no-full
				height="auto"
				title="Listado de Empaquetadores "
				ref="gridPackerslist"
				@rowSelected="rowSelected($event)"				
			>
				<template v-slot:filter>
					<v-container>
						<v-row style="margin-left: 5px" justify="center">
							<v-col class="pt-6">
								<s-text
									label="Nombre Completos"
									v-model="filter.NtpFullName"
									outlined
								></s-text>
							</v-col>
							
						</v-row>
					</v-container>
				</template>
				<template v-slot:Print="{ row }">
					<!--v-if="(filter.TypeAre == 2 || filter.TypeAre == 3) && selected.TypeShip  == 1"-->
					<v-btn
						
						x-small
						dark
						:color="'success'"
						style="border-radius: 13px; margin: 10px 10px 10px 10px;"
						@click="clickPrint(row)"
					>
						<v-icon>mdi-printer</v-icon>
					</v-btn>
			</template>
			</s-crud>

			<v-dialog
				v-if="showModalPrint"
				v-model="showModalPrint"
				width="60%"
				persistent
			>
				<turn-print-label :item="selectedRow"  @close="closePrint()"></turn-print-label>
			</v-dialog>
		</div>

	</v-container>
</template>

<script>
	//Service
	import _sPackersListService  from "@/services/FreshProduction/PrfPackersService";
	import TurnPrintLabel from "@/views/FreshProduction/Turn/TurnPrintLabel";
	
	export default {
		components: { 
			TurnPrintLabel
		 },

		data: () => ({
			itemsSelected: [],			
			showModalPrint: false,
			selectedRow: {},
			filter: {								
				AreaID: 27
			},
			config: {
				service: _sPackersListService,
				model: {
					CtrID: "ID",					
					Print: "",
				},
				headers: [
					{ text: "ID", 					value: "CtrID", 			width: 10 	},
					{ text: "DNI", 					value: "PrsDocumentNumber", width: 50 	},
					{ text: "NOMBRES Y APELLIDOS", 	value: "NtpFullName", 		width: 150 	},
					{ text: "POSICION", 			value: "PstName", 			width: 150 	},
					{ text: "AREA", 				value: "AreName", 			width: 150 	},
					{ text: "DESCRIPCION", 			value: "AtaDescription", 	width: 150 	},
					{ text: "Print", 				value: "Print", 			width: 10  	},
				]
			},
		}),

		watch: {
		},

		methods: {

			rowSelected(item){
				console.log("Item seleccionado",item);
			},
			clickPrint(item) {				
				this.selectedRow = item
				this.showModalPrint = true;
			},

			closePrint() {
				this.showModalPrint = false;
				this.$refs.gridPackerslist.refresh();
			},
        
        },

    };
</script>
