import Service from "../Service";
const resource = "PrfTurnConfig/"

export default {    

    pagination(obj, requestID) {
        console.log("Filter",obj);
        return Service.post(resource + "paginationPackers", obj, {
            params: { requestID: requestID }
        });
    },
}